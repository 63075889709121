import Big from 'big.js';
import {
  filter,
  find,
  forEach,
  head,
  sumBy,
  without
} from "lodash";
import { pluck } from '../utils/util';

export const unifySimilarProducts = (orderItems) => {
  const unifyOrderItems = [];

  let newOrderItems = [...orderItems];
  let similarItems = [];
  let itemWithId = null;

  while (newOrderItems.length > 0) {
    const orderItem = newOrderItems[0];

    similarItems = filterItems(orderItems, orderItem);
    itemWithId = find(similarItems, (o) => o.id != null || o.id != undefined);

    const firstItem = head(similarItems);
    const quantity = sumQuantities(similarItems);
    const subtotal = Number(Big(Number(quantity)).times(Big(Number(firstItem.unit_price))));

    const newItem = {
      ...firstItem,
      quantity,
      subtotal,
      taxes: Number(Big(Number(subtotal)).times(Number(firstItem.custom_tax)).div(100).round(2))
    };

    if (itemWithId) {
      newItem.id = itemWithId.id;
    }

    unifyOrderItems.push(newItem);

    newOrderItems = removeAddedProduct(newOrderItems, similarItems);
  }

  return { unifyOrderItems };
};

const filterItems = (orderItems, orderItem) => {
  return filter(orderItems, (item) => {
    return item.web_id == orderItem.web_id;
  });
};

const hasSameVariants = (variant1, variant2) => {
  (variant1.id == variant2.id);
}

const sumQuantities = (similarItems) => {
  return sumBy(similarItems, (item) => { return Number(item.quantity); })
};

const removeAddedProduct = (newOrderItems, similarItems = []) => {
  forEach(similarItems, (item) => {
    newOrderItems = without(newOrderItems, item);
  });

  return newOrderItems;
}