import React from 'react';
import Big from 'big.js';
import { isEmpty } from 'lodash';

import TrashIcon from 'images/Trash-2-outline-red.svg';
import TrashIconBlack from 'images/Trash-2-outline.svg';
import { useDispatch, useSelector } from 'react-redux';
import CatalogProductQuantity from '../CatalogProductQuantity';
import SubtotalFooter from '../SubtotalFooter';
import BackIcon from '../../../../assets/images/Arrow-ios-back-fill.svg';
import ProductExample from 'images/img-holder.png';
import { emptyCart, removeLine, updateLine } from '../../../actions/cart';
import { variantsHowText } from '../../../utils/util';
import { formatMoney } from '../../../utils/number';

const Cart = () => {
  const { orderLines } = useSelector((reduxState) => reduxState.cartReducer);
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);

  const dispatch = useDispatch();

  const onChangeQuantity = (line, quantity) => {
    const newOrderLines = [...orderLines];
    const lineIndex = newOrderLines.lastIndexOf(line);

    if (lineIndex > -1) {
      if (quantity) {
        const lineToUpdate = newOrderLines[lineIndex];

        lineToUpdate.quantity = quantity;
        lineToUpdate.subtotal = Number(Big(Number(line.quantity)).times(Big(Number(line.product?.price))));
        lineToUpdate.taxes = Number(Big(Number(lineToUpdate.subtotal)).times(Number(retailer.tax_amount)).div(100));

        dispatch(updateLine(newOrderLines));
      } else {
        dispatch(removeLine(line));
      }
    }
  };

  const renderLines = () => orderLines.map((line, index) => {
    const images = line.product?.image_urls || [];
    const image = images.length && images[0].url || line.product?.attached_image?.url || line.product?.product_image?.url || ProductExample;

    return (
      <div key={`line-${line.web_id}-${index}`} className="d-flex cart-item">
        <div className="cart-image me-3">
          <img src={image} alt="" />
        </div>
        <div className="d-flex flex-column flex-md-row flex-grow-1">
          <div className="flex-grow-1 d-flex flex-column">
            {line.product.title || line.product.product_title || line.title}
            {!isEmpty(line.variants) && (
              <span className='text-muted' style={{ fontSize: '12px' }}>{variantsHowText(line.variants)}</span>
            )}
            {line.product.is_combination && (
              <span className='text-muted' style={{ fontSize: '12px' }}>{line.product.name}</span>
            )}
            <span className="text-muted pt-1">
              {retailer.currency}{formatMoney(line.subtotal)} {retailer.currency_code}
            </span>
          </div>
          <div className="mt-2 mt-md-0">
            <CatalogProductQuantity
              quantity={line.quantity}
              onChangeQuantity={(newQuantity) => onChangeQuantity(line, newQuantity)}
            />
          </div>
        </div>
      </div>
    )
  });
  return (
    <div className="offcanvas offcanvas-end cart-offcanvas" tabIndex="-1" id="offcanvasCart" aria-labelledby="offcanvasCartLabel">
      <div className="offcanvas-header mt-3 d-flex align-items-center">
        <button
          type="button"
          className="btn d-md-none"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasCart"
        >
          <img src={BackIcon} alt="" />
        </button>
        <h3 className="fw-bold mb-0" id="offcanvasCartLabel">Mi carrito</h3>
        <button
          type="button"
          className="btn d-md-none"
          onClick={() => dispatch(emptyCart())}
        >
          <img src={TrashIconBlack} alt="" />
        </button>
        <button
          type="button"
          className="btn-close d-none d-md-block"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasCart"
          aria-label="Close"
        />
      </div>
      <div className="offcanvas-body">
        {renderLines()}
        {orderLines.length > 0 && (
          <button
            type="button"
            className="btn btn-delete-cart text-danger w-100 justify-content-center align-items-center d-none d-md-flex"
            onClick={() => dispatch(emptyCart())}
          >
            <img src={TrashIcon} alt="" className="me-2" />
            Vaciar carrito
          </button>
        )}
      </div>
      {orderLines.length > 0 && (
        <SubtotalFooter />
      )}
    </div>
  );
};

export default Cart;
