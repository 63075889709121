import { isEmpty, filter } from 'lodash';

export const validateAvailable = (item) => {
  return !item.track_quantity || item.selling_without_stock || Number(item.quantity) > 0;
};

export const canBeSelected = (product = {}) => {
  if (!isEmpty(product.product_variant_combinations)) {
    const availables = filter(product.product_variant_combinations, (item) => validateAvailable(item));

    if (!isEmpty(availables)) return true;

    return false;
  };

  return validateAvailable(product);
};

export const orderByAvailable = (products) => {
  const availableProducts = filter(products, (item) => canBeSelected(item));
  const unavailableProducts = filter(products, (item) => !canBeSelected(item))

  return availableProducts.concat(unavailableProducts);
};
