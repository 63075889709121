import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PrevIcon from 'images/nav-left.svg';
import NextIcon from 'images/nav-right.svg';
import { Carousel } from 'bootstrap';
import { orderBy, isEmpty } from 'lodash';
import { canBeSelected } from '../../../../../utils/productUtils';

const ProductImagesCarousel = ({ product, images, isSmallDevice }) => {
  const { retailer = {} } = useSelector((reduxState) => reduxState.retailerReducer);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [thumbnails, setThumbnails] = useState([]);
  const [orderedImages, setOrderedImages] = useState([]);

  const carouselListener = ({ to }) => {
    setCurrentImageIndex(to);
  };

  useEffect(() => {
    const myCarousel = document.getElementById('imageCarousel');
    myCarousel.addEventListener('slide.bs.carousel', carouselListener);

    if (retailer.rotate_images_in_catalog && myCarousel) {
      new Carousel(myCarousel).cycle();
    }

    return () => {
      myCarousel.removeEventListener('slide.bs.carousel', carouselListener);
    };
  }, []);

  useEffect(() => {
    if (images.length > 1) {
      const newImages = orderBy(images, ['index'], ['asc']);
      setOrderedImages(newImages);

      const imageMapped = newImages.map((image, index) => ({ image, index }));

      const allImages = [
        ...imageMapped.slice(currentImageIndex + 1),
        ...imageMapped.slice(0, currentImageIndex)
      ];
      setThumbnails(allImages.slice(0, allImages.length > 2 ? 3 : allImages.length));
      return;
    }

    setOrderedImages(images);
    setThumbnails([]);

  }, [currentImageIndex, images]);

  const onPressThumbnail = (e, index) => {
    e.preventDefault();
    const myCarousel = document.getElementById('imageCarousel');
    if (myCarousel) {
      new Carousel(myCarousel).to(index);
    }
  };

  return (
    <div className="d-flex flex-column justify-content-center align-items-center pt-md-5 pb-md-5 position-relative">
      <div
        id="imageCarousel"
        className="carousel slide product-image-carousel"
        data-bs-ride="carousel"
        data-bs-interval="3000"
      >
        <div className="carousel-inner" style={{ height: 'auto' }}>
          {orderedImages.map((image, key) => (
            <div className={`carousel-item ${key === 0 ? 'active' : ''}`}
              key={isEmpty(thumbnails) ? `${Date.now()}-${key.toString()}` : key.toString()}>
              <div className="product-image-item">
                <img src={image.url} alt="" style={{ height: 'auto' }} className={!canBeSelected(product) ? 'opacity-50' : ''} />
              </div>
            </div>
          ))}
        </div>
        <button
          className="carousel-control-prev d-none d-md-block"
          type="button"
          data-bs-target="#imageCarousel"
          data-bs-slide="prev"
        >
          <img src={PrevIcon} alt="" />
        </button>
        <button
          className="carousel-control-next d-none d-md-block"
          type="button"
          data-bs-target="#imageCarousel"
          data-bs-slide="next"
        >
          <img src={NextIcon} alt="" />
        </button>
      </div>
      {!isSmallDevice && (
        <div className="d-flex flex-row mt-3">
          {thumbnails.map((thumbnail) => (
            <div
              className=" product-image-thumbnail"
              key={thumbnail.index}
              onClick={(e) => onPressThumbnail(e, thumbnail.index)}
            >
              <img src={thumbnail.image.url} alt="" />
            </div>
          ))}
        </div>
      )}
      <div className="product-image-carousel-indicators carousel-indicators">
        {orderedImages.map((image, key) => (
          <button
            key={key.toString()}
            type="button"
            data-bs-target="#imageCarousel"
            data-bs-slide-to={key}
            className={key === currentImageIndex ? 'active' : ''}
            aria-current="true"
          />
        ))}
      </div>
    </div>
  );
};

export default ProductImagesCarousel;
