import React, { useEffect, useState } from "react";
import { isEmpty, filter, head, forEach } from 'lodash';

const VariantsComponent = ({
  product,
  setSelectedProduct
}) => {
  const { product_variants, product_variant_combinations } = product;

  const [variants, setVariants] = useState([]);

  useEffect(() => {
    if (isEmpty(product_variant_combinations)) {
      setSelectedProduct(product);
      return;
    }

    const newVariants = selectInitialCombination();
    setVariants(setEnabledCombinations(newVariants));
  }, [product_variants, product_variant_combinations]);

  const selectInitialCombination = () => {
    let firstCombIds = [];

    if (!isEmpty(product_variant_combinations)) {
      firstCombIds = head(product_variant_combinations).variant_option_web_ids;
      setSelectedProduct(head(product_variant_combinations));
    }

    return product_variants.map((variant, variantIndex) => {
      const options = variant.product_variant_options.map((opt) => {
        const isSelected = firstCombIds.includes(opt.web_id);
        return {
          ...opt, selected: isSelected, available: true, variant_index: variantIndex
        };
      });
      return { ...variant, product_variant_options: options, index: variantIndex };
    });
  };

  const getSelectedCombinationsUntil = (variants, until) => {
    if (until < 0) return [];

    const webIds = [];

    forEach(variants, (variant) => {
      forEach(variant.product_variant_options, (option) => {
        if (variant.index <= until && option.selected) {
          webIds.push(option.web_id);
        }
      });
    });

    return webIds;
  };

  const setEnabledCombinations = (variants) => variants.map((variant) => {
    const options = variant.product_variant_options.map((opt) => ({ ...opt, available: existCombination([...getSelectedCombinationsUntil(variants, variant.index - 1), opt.web_id]) }));

    return { ...variant, product_variant_options: options };
  });

  const existCombination = (selectedWebIds) => !isEmpty(filter(product_variant_combinations, (o) => selectedWebIds.every((element) => o.variant_option_web_ids.includes(element))));

  const handleSelect = (option) => {
    const newVariants = variants.map((variant) => {
      const options = variant.product_variant_options.map((opt) => {
        if (variant.index === option.variant_index) {
          if (option.web_id === opt.web_id) {
            return { ...opt, selected: true };
          }
          return { ...opt, selected: false };
        }
        return opt;
      });

      return { ...variant, product_variant_options: options };
    });

    const selectdCombinations = getSelectedCombinationsUntil(newVariants, newVariants.length);
    const combinations = filter(product_variant_combinations, (o) => selectdCombinations.every((element) => o.variant_option_web_ids.includes(element)));

    setSelectedProduct(combinations[0]);
    setVariants(setEnabledCombinations(newVariants));
  };

  return (
    <div className="col-md-12">
      {variants.map((variant) => (
        <div key={`variant-web-${variant.id}`} className="d-flex flex-column">
          <div className="py-2">
            <span>{variant.name}</span>
          </div>

          <div className="d-flex">
            {variant.product_variant_options.map((opt) => (
              <button
                type="button"
                key={`variant-opt-${opt.web_id}`}
                className={`variant-button mr-10 ${opt.selected && opt.available ? 'selected' : ''} ${opt.available ? '' : 'unavailable'}`}
                onClick={() => handleSelect(opt)}
                disabled={!opt.available}
              >
                <span>{opt.name}</span>
              </button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VariantsComponent;
