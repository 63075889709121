import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { formatMoney } from '../../../utils/number';
import { canBeSelected } from '../../../utils/productUtils';

const CatalogProductItem = ({
  product,
  onSelectProduct
}) => {
  const {
    title,
    price,
    image_url: imageUrl,
    product_variant_combinations
  } = product;
  const { retailer } = useSelector((reduxState) => reduxState.retailerReducer);
  const hasCombinations = !isEmpty(product_variant_combinations);

  let image = '';

  if (imageUrl) {
    image = imageUrl.url;
  };

  const onClickProduct = () => {
    if (window.innerWidth > 760) {
      onSelectProduct(product);
    } else {
      window.location.href = `/product/${product.web_id}`;
    }
  };

  const getFromPrice = () => {
    const prices = product_variant_combinations.map(combination => Number(combination.price));

    return Number(Math.min(...prices));
  };

  const getProductPrice = () => {
    if (!hasCombinations) return price;

    return getFromPrice();
  };

  return (
    <div className="col-6 col-md-4 catalog-product-item pb-4">
      <div onClick={onClickProduct} role="button">
        <div className="catalog-product-item-image">
          <img src={image} alt="" className={!canBeSelected(product) ? 'opacity-50' : ''} />
        </div>
        <p className="pt-3 mb-1 catalog-product-item-name">{title}</p>
        <p className="catalog-product-item-price">
          {hasCombinations && ('Desde ')}{retailer.currency}{formatMoney(getProductPrice())} {retailer.currency_code}
        </p>
        {canBeSelected(product) && (
          <button
            type="button"
            className="btn btn-main text-white d-none d-md-block"
          >
            Comprar
          </button>
        )}

        {!canBeSelected(product) && (
          <span className="sold-out-product">Agotado</span>
        )}
      </div>
    </div>
  );
};

export default CatalogProductItem;
